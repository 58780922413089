import React, { useState } from 'react';
import { ArrowDown, ArrowUp } from 'lucide-react';

const SkillsGrid = () => {
  const skills = ['Golang', 'Python', 'React', 'Service Monitoring', 'DevOps', 'Flask', 'Django', 'Tailwind', 'TypeScript', 'Docker', 'Bash Scripting', 'Linux', 'Gin', 'Database', 'Prompt Engineering'];
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleSkills = isExpanded ? skills : skills.slice(0, 9);

  return (
    <div className="container mx-auto px-6">
      <h2 className="text-4xl font-bold mb-8 text-purple-400">&lt;Skills /&gt;</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
        {visibleSkills.map((skill) => (
          <div key={skill} className="bg-gray-800 p-4 rounded-lg text-center border border-purple-500 transform hover:scale-110 transition-transform duration-300 shadow-neon">
            {skill}
          </div>
        ))}
      </div>
      {skills.length > 9 && (
        <button 
          className="m-4"
          onClick={() => setIsExpanded(!isExpanded)}
        >
            
          {isExpanded ? (
            <>
            <ArrowUp className="ml-2" size={20} />
            </>
          ) : (
            <>
              <ArrowDown className="ml-2" size={20} />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default SkillsGrid;