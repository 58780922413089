
import './App.css';
import MainPage from './components/home';

function App() {
  return (
    <div className="App">
    <MainPage/>
    </div>
  );
}

export default App;
