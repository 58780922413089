import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Mail, Phone, Linkedin, Github, Terminal } from 'lucide-react';
import CustomTerminal from './terminal';
import SkillsGrid from './skills';



const MainPage: React.FC = () => {
    const [scrollY, setScrollY] = useState(0);  // eslint-disable-line @typescript-eslint/no-unused-vars,
    const [terminalOpen, setTerminalOpen] = useState(false);
    const [typedTerminal, settypedTerminal] = useState(false);

    const [terminalText, setTerminalText] = useState(''); // eslint-disable-line @typescript-eslint/no-unused-vars,
    const [currentSection, setCurrentSection] = useState('');
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const terminalRef = useRef<HTMLDivElement>(null);

  const [glassShards, setGlassShards] = useState<Array<{ x: number; y: number; size: number; rotation: number }>>([]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      const sections = ['hero', 'about', 'experience', 'skills', 'contact'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom > 100;
        }
        return false;
      });
      if (current) setCurrentSection(current);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const generateGlassShards = useCallback(() => {
    const shards = Array.from({ length: 20 }, () => ({
      x: Math.random() * 100,
      y: Math.random() * 100,
      size: Math.random() * 50 + 10,
      rotation: Math.random() * 360,
    }));
    setGlassShards(shards);
  }, []);

  useEffect(() => {
    generateGlassShards();
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [generateGlassShards]);

  useEffect(() => {
    if (terminalOpen && !typedTerminal) {
      const text = "Loading profile...\nName: John Doe\nRole: Full Stack Developer\nStatus: Ready for new challenges\nSkills: React, Node.js, TypeScript, GraphQL, AWS, Docker\n\n> Type 'help' for available commands";
      let i = 0;
      const intervalId = setInterval(() => {
        setTerminalText((prev) => {
          const newChar = Math.random() > 0.1 ? text[i] : '';
          const newText = prev + newChar;
          if (terminalRef.current) {
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
          }
          return newText;
        });
        i++;
        if (i === text.length) clearInterval(intervalId);
      }, 25);
      settypedTerminal(true);
      return () => clearInterval(intervalId);
    }
  }, [terminalOpen,typedTerminal]);


  return (
    <div className="min-h-screen bg-gray-900 text-gray-100 font-mono">
      <header className="fixed top-0 left-0 right-0 z-50 bg-black bg-opacity-80 backdrop-blur-md border-b border-purple-500">
        <nav className="container mx-auto px-6 py-4">
          <ul className="hidden sm:flex justify-end space-x-8">
            {['About', 'Experience', 'Skills', 'Contact'].map((item) => (
              <li key={item}>
                <a 
                  href={`#${item.toLowerCase()}`} 
                  className={`hover:text-purple-400 transition-colors ${currentSection === item.toLowerCase() ? 'text-purple-400' : ''}`}
                >
                  {`<${item} />`}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      <main className="">
        <section id="hero" className="h-screen flex items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-purple-900 to-black broken-glass">
            {glassShards.map((shard, index) => (
              <div
                key={index}
                className="absolute bg-white bg-opacity-10 backdrop-blur-sm"
                style={{
                  left: `${shard.x}%`,
                  top: `${shard.y}%`,
                  width: `${shard.size}px`,
                  height: `${shard.size}px`,
                  transform: `rotate(${shard.rotation}deg) translate(${(mousePosition.x - window.innerWidth / 2) / 50}px, ${(mousePosition.y - window.innerHeight / 2) / 50}px)`,
                  transition: 'transform 0.1s ease',
                }}
              ></div>
            ))}
          </div>
          <div className="text-center z-10 space-y-6 flex flex-col items-center justify-center">
            <h1 className="text-6xl font-bold mb-4 glitch" data-text="Parsa Hosseini">Parsa Hosseini</h1>
            <p className="text-2xl text-purple-300 typewriter">Full Stack Developer</p>
            <button 
              onClick={() => setTerminalOpen(true)}
              className="px-6 py-3 bg-purple-600 hover:bg-purple-700 rounded-md transition-colors flex items-center space-x-2"
            >
              <Terminal size={20} />
              <span>Open Terminal</span>
            </button>
          </div>
        </section>

       
        <section id="about" className="py-20 bg-black">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold mb-8 text-purple-400">&lt;About /&gt;</h2>
            <div className="bg-gray-800 p-6 rounded-lg border border-purple-500 shadow-neon">
              <p className="text-lg leading-relaxed">
              Experienced Software Developer adept in bringing forth expertise in design, installation, testing and maintenance of software systems. Proficient in various platforms,
languages, and embedded systems. Experienced with the latest cutting edge development tools and procedures. Able to effectively self-manage during independent
projects, as well as collaborate as part of a productive team.
              </p>
            </div>
          </div>
        </section>

        <section id="experience" className="py-20 bg-gray-900">
  <div className="container mx-auto px-6">
    <h2 className="text-4xl font-bold mb-8 text-purple-400">&lt;Experience /&gt;</h2>
    <div className="space-y-12">
      {[
        {
          title: "Senior Software Engineer",
          company: "Kheradmen, Tehran",
          period: "June 2022 - Present",
          achievements: [
            "Working on Python back-end for AI solutions in research and medical purposes",
            "Involved in a startup focusing on various fields including AI"
          ]
        },
        {
          title: "IT Support Staff",
          company: "Sindad, Tehran",
          period: "October 2021 - October 2022",
          achievements: [
            "Learned to interact with teams and customers",
            "Heightened knowledge on network engineering and application/service level monitoring",
            "Overcame introverted nature through customer interactions"
          ]
        },
        {
          title: "Freelancer",
          company: "Tehran",
          period: "December 2018 - September 2021",
          achievements: [
            "Worked on various projects including web scraping in Python",
            "Learned C# fundamentals",
            "Engaged in content creation",
            "Performed interpretation from English to Persian",
            "Balanced freelance work while studying for bachelor's degree"
          ]
        },
        {
          title: "Expert in General Support",
          company: "Rahyab, Tehran",
          period: "October 2017 - August 2018",
          achievements: [
            "First part-time internship as support and part-time developer",
            "Learned to code in Swift 4",
            "Built basic iOS apps"
          ]
        }
      ].map((job, index) => (
        <div key={index} className="bg-black p-6 rounded-lg border border-purple-500 shadow-neon transform hover:scale-105 transition-transform duration-300">
          <h3 className="text-2xl font-semibold mb-2">{job.title}</h3>
          <p className="text-purple-300 mb-4">{job.company} | {job.period}</p>
          <ul className="list-disc list-inside space-y-2">
            {job.achievements.map((achievement, i) => (
              <li key={i}>{achievement}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  </div>
</section>

        <section id="skills" className="py-10 bg-black">
          <SkillsGrid/>
 
        </section>

        <section id="contact" className="py-20 bg-gray-900">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold mb-8 text-purple-400">&lt;Contact /&gt;</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { icon: Mail, text: "hparsa1378@gmail.com", href: "mailto:hparsa1378@gmail.com" },
                { icon: Phone, text: "+1 (310) 425-3667", href: "tel:+13104253667" },
                { icon: Phone, text: "+98 09334797250", href: "tel:+98 09334797250" },
                { icon: Linkedin, text: "https://www.linkedin.com/in/sspphh78/", href: "https://www.linkedin.com/in/sspphh78/" },
                { icon: Github, text: "https://github.com/uussoop", href: "https://github.com/uussoop" }
              ].map((item, index) => (
                <a key={index} href={item.href} target="_blank" rel="noopener noreferrer" 
                   className="flex items-center space-x-4 bg-black p-4 rounded-lg border border-purple-500 hover:bg-purple-900 transition-colors shadow-neon">
                  <item.icon size={24} className="text-purple-400" />
                  <span>{item.text}</span>
                </a>
              ))}
            </div>
          </div>
        </section>
      </main>
      <footer className="bg-black py-6 text-center border-t border-purple-500">
        <p>&copy; {new Date().getFullYear() } PARSA HOSSEINI. All rights reserved.</p>
      </footer>


      {terminalOpen && (
        <div className="fixed inset-0 bg-opacity-75 top-1/4 text-left   z-50 w-full  max-w-[612px] h-[400px] mx-auto my-5 border border-green-500 rounded-md overflow-hidden bg-[#2b2b2b]">
        <CustomTerminal setTerminalOpen={setTerminalOpen}/>
      </div>
   
      )}

      <style jsx>{`
        .shadow-neon {
          box-shadow: 0 0 5px theme('colors.purple.500'), 0 0 20px theme('colors.purple.500');
        }
        

        .broken-glass {
          filter: blur(1px);
        }
        .broken-glass::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
          opacity: 0.3;
          mix-blend-mode: overlay;
          pointer-events: none;
        }
        .glitch-container {
          animation: glitch-skew 1s infinite linear alternate-reverse;
        }
        .glitch-text {
          animation: glitch-skew 1s infinite linear alternate-reverse;
        }
        .glitch-char {
          animation: glitch-char 0.5s infinite linear alternate-reverse;
        }
        .glitch-input {
          animation: glitch-input 2s infinite linear;
        }
        @keyframes glitch-skew {
          0% { transform: skew(-2deg); }
          100% { transform: skew(2deg); }
        }
        @keyframes glitch-char {
          0% { transform: translate(-2px, 2px); }
          100% { transform: translate(2px, -2px); }
        }
        @keyframes glitch-input {
          0% { opacity: 1; }
          50% { opacity: 0.8; }
          100% { opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default MainPage;