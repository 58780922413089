import React, { useEffect } from 'react';
import { TerminalService } from 'primereact/terminalservice';

import { Terminal,TerminalProps } from 'primereact/terminal';
interface SubCommand {
  description: string;
  execute: () => string;
}

interface Command {
  description: string;
  execute: () => string;
  subCommands?: {
    [key: string]: SubCommand;
  };
}

interface CommandsType {
  [key: string]: Command;
}
const commands: CommandsType = {
  help: {
    description: 'List all available commands',
    execute: () => 'Available commands: \n' + Object.keys(commands).join('\n'),
  },
  about: {
    description: 'Get a brief introduction',
    execute: () => "I'm Parsa Hosseini, a Senior Software Engineer with expertise in AI solutions, back-end development, and DevOps.",
    subCommands: {
      summary: {
        description: 'Get a summary of my professional profile',
        execute: () => "Experienced Software Developer adept in design, installation, testing, and maintenance of software systems. Proficient in various platforms, languages, and embedded systems. Skilled with cutting-edge development tools and procedures.",
      },
      location: {
        description: 'Get my current location',
        execute: () => 'Tehran, Iran',
      },
    },
  },
  skills: {
    description: 'List my technical skills',
    execute: () => Object.keys(commands.skills.subCommands!).join('\n'),
    subCommands: {
      programming: {
        description: 'List my programming language skills',
        execute: () => 'Golang (★★★☆☆)\nPython (★★★☆☆)\nTypeScript (★★☆☆☆)\nBash Scripting (★★☆☆☆)',
      },
      frameworks: {
        description: 'List my framework skills',
        execute: () => 'React (★★☆☆☆)\nFlask (★★★☆☆)\nDjango (★★★☆☆)\nGin (★★★☆☆)',
      },
      devops: {
        description: 'List my DevOps skills',
        execute: () => 'Docker (★★★☆☆)\nLinux (★★★☆☆),\nService Monitoring (★★★☆☆)',
      },
      other: {
        description: 'List my other technical skills',
        execute: () => 'Tailwind (★★★☆☆)\nDatabase (★★★☆☆)\nPrompt Engineering (★★★☆☆)',
      },
    },
  },
  experience: {
    description: 'Get my work experience',
    execute: () => Object.keys(commands.experience.subCommands!).join('\n'),
    subCommands: {
      kheradmen: {
        description: 'Get details about my current job at Kheradmen',
        execute: () => 'Senior Software Engineer at Kheradmen (June 2022 - Present): Working on Python back-end, data processing, React front-end, Golang back-end, and monitoring solutions.',
      },
      sindad: {
        description: 'Get details about my job at Sindad',
        execute: () => 'IT Support Staff at Sindad (October 2021 - October 2022): Learned team interaction, customer service, network engineering, and application/service level monitoring.',
      },
      freelance: {
        description: 'Get details about my freelance work',
        execute: () => 'Freelancer (December 2018 - September 2021): Worked on various projects including web scraping in Python, C# fundamentals, content creation, and English to Persian interpretation.',
      },
      rahyab: {
        description: 'Get details about my internship at Rahyab',
        execute: () => 'Expert in General Support at Rahyab (October 2017 - August 2018): First part-time internship as support and part-time developer. Learned Swift 4 and built basic iOS apps.',
      },
    },
  },
  education: {
    description: 'Get my educational background',
    execute: () => Object.keys(commands.education.subCommands!).join('\n'),
    subCommands: {
      masters: {
        description: 'Get details about my Masters degree',
        execute: () => 'AI and Robotics Masters at Islamic Azad University Electronics Branch, Tehran (July 2022 - Present)',
      },
      bachelors: {
        description: 'Get details about my Bachelors degree',
        execute: () => 'Software Engineering Bachelor at Islamic Azad University East Branch, Tehran (October 2017 - October 2022)',
      },
    },
  },
  contact: {
    description: 'Get my contact information',
    execute: () => 'Location: Tehran, Iran | Social: Telegram',
  },
};


const CustomTerminal: React.FC<{ setTerminalOpen: React.Dispatch<React.SetStateAction<boolean>>}> = ({setTerminalOpen }) => {
  const commandHandler = (text: string) => {
    let response: string | null = null;
    let argsIndex = text.indexOf(' ');
    let command = argsIndex !== -1 ? text.substring(0, argsIndex) : text;
    let args = argsIndex !== -1 ? text.substring(argsIndex + 1) : '';

    const executeCommand = (cmd: any, args: string): string => {
        if (typeof cmd.execute === 'function') {
            return cmd.execute(args);
        }
        return 'Command execution not implemented';
    };

    const handleSubCommand = (cmd: any, args: string): string => {
        const [subCmd, ...subArgs] = args.split(' ');
        if (cmd.subCommands && cmd.subCommands[subCmd]) {
            return executeCommand(cmd.subCommands[subCmd], subArgs.join(' '));
        }
        return executeCommand(cmd, args);
    };

    switch (command) {
        case 'help':
            response = executeCommand(commands.help, args);
            break;

        case 'about':
            response = handleSubCommand(commands.about, args);
            break;

        case 'skills':
            response = handleSubCommand(commands.skills, args);
            break;

        case 'experience':
            response = handleSubCommand(commands.experience, args);
            break;

        case 'education':
            response = handleSubCommand(commands.education, args);
            break;

        case 'contact':
            response = executeCommand(commands.contact, args);
            break;

        default:
            response = 'Unknown command: ' + command;
            break;
    }

    if (response)
        TerminalService.emit('response', response);
    else
        TerminalService.emit('clear');
};
const terminalProps: TerminalProps = {
  pt: {
    root: { className: 'bg-gray-900 text-white border-round h-full px-5 pt-2 pb-5 whitespace-pre-wrap	' },
    prompt: { className: 'text-gray-400 mr-2 whitespace-pre-wrap	' },
    command: { className: 'text-primary-300 whitespace-pre-wrap	' },
    response: { className: 'text-primary-300 whitespace-pre-wrap	' }
  },
  welcomeMessage:"Welcome to learn more about parsa\nuse help to see whats available" ,
        prompt:"parsa $" 
};
useEffect(() => {
    TerminalService.on('command', commandHandler);

    return () => {
        TerminalService.off('command', commandHandler);
    };
}, []);

  

  
  return (
<>

    <div className='flex flex-col w-full h-full bg-gray-900'>
    <div 
      className="m-2 w-3 h-3 bg-red-500 rounded-full cursor-pointer"
      onClick={() => {
        setTerminalOpen(false)
      }}
    ></div>
    <Terminal 
       {...terminalProps}
    />
    
    </div>
    </>


  );
};

export default CustomTerminal;